import { reloadCardRequest } from '@/shared/api/services/cards';

export const checkOwner = async (cardAddress: string | undefined) => {
  if (cardAddress) {
    const checkOwnerRequest = (await reloadCardRequest(cardAddress)) as {
      data: {
        message: string;
        changeOwner: boolean;
        isMagicEden?: boolean;
      };
    };
    if (checkOwnerRequest.data?.isMagicEden) return 'magic-eden-error';

    if (checkOwnerRequest.data.changeOwner) return 'owner-error';

    return 'success';
  }
};
