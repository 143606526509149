import './cancelOfferWarning.scss';
import { Buffer } from 'buffer';
import { useWallet } from '@solana/wallet-adapter-react';
import { Transaction } from '@solana/web3.js';
import axios from 'axios';
import { FC, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorBlock } from '../../ErrorBlock/ErrorBlock';
import { IErrorNames } from '../../ErrorBlock/types';
import CancelOfferOrListingLoading from '../LoadingScreen/LoadingScreen';
import { ICancelDefaultModalProps } from '../types';
import { getListings } from '@/pages/Marketplace';
import {
  createCancelListingTxRequest,
  createCancelOfferTxRequest,
  sendCancelListingTxsRequest,
  sendCancelOfferTxsRequest,
} from '@/shared/api/services/listings';
import { WarningTriangleSVG } from '@/shared/assets/svg/AlertsIcons';
import { AuthProviderContext } from '@/shared/lib/context/AuthProviderContext';
import { checkOwner } from '@/shared/lib/helpers/onListingOwnerCheck';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { signTransactionsMethod } from '@/shared/lib/signTransactions/signTransactionsMethod';
import { Button } from '@/shared/ui/Buttons';

const CancelOfferOrListingDefault: FC<ICancelDefaultModalProps> = ({
  getDataCard,
  handleModalClose,
  subjectId,
  cardData,
  transactionStatus,
  setTransactionStatus,
  getOffers,
  typeModal,
  setTransaction,
  currentUserWallet,
  setError,
}) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { signTransaction } = useWallet();
  const { signTrans: signTransactionWeb3 } = useContext(AuthProviderContext);

  const { tokenId, cardAddress, ownerWallet } = cardData;
  const isPendingTransaction = transactionStatus === 'pending';
  const [errors, setErrors] = useState<IErrorNames[]>([]);

  const cancelOfferFunction = async () => {
    setTransactionStatus('pending');
    try {
      const createTx = async () => {
        if (typeModal === 'listing') {
          if ((await checkOwner(cardAddress)) === 'owner-error') {
            if (pathname.includes('/assets/solana/'))
              getDataCard({
                cardAddress: cardAddress || '',
                withLoading: false,
              });
            else
              dispatch(
                getListings({
                  params: { wallet: ownerWallet },
                }),
              );

            if (currentUserWallet !== ownerWallet)
              throw new Error('owner-error');
          }
          const createdTx = (await createCancelListingTxRequest({
            listingId: subjectId,
          })) as { data: string };

          return createdTx.data;
        }

        if (typeModal === 'offer') {
          const createdTx = (await createCancelOfferTxRequest({
            offerId: subjectId,
          })) as { data: string };

          return createdTx.data;
        }
      };

      const ut = createTx() as Promise<string>;

      const transaction = Transaction.from(
        Buffer.from(await ut, 'base64'),
      ) as Transaction;

      const transactionSigned = await signTransactionsMethod(
        transaction,
        signTransaction,
        signTransactionWeb3,
      );
      if (transactionSigned) {
        const serializedTransactions = transactionSigned
          .serialize()
          .toString('base64');

        if (typeModal === 'listing') {
          const transactionSended = (await sendCancelListingTxsRequest({
            tx: serializedTransactions,
          })) as {
            data: {
              txId: string;
              txUrl: string;
            };
          };
          setTransaction({
            transactionId: transactionSended.data.txId,
            transactionUrl: transactionSended.data.txUrl,
          });
        }

        if (typeModal === 'offer') {
          const transactionSended = (await sendCancelOfferTxsRequest({
            tx: serializedTransactions,
          })) as {
            data: {
              txId: string;
              txUrl: string;
            };
          };
          setTransaction({
            transactionId: transactionSended.data.txId,
            transactionUrl: transactionSended.data.txUrl,
          });
        }
        getDataCard({ cardAddress: tokenId || '', withLoading: false });
        getOffers();
        setTransactionStatus('resolved');
      }
    } catch (err) {
      getDataCard({ cardAddress: tokenId || '', withLoading: false });
      setError('error');
      setTransactionStatus('adapter-error');
      if (axios.isAxiosError(err)) {
        setTransactionStatus('axios-error');
        const customError = err.response as IResponse<IErrorMessage>;
        if (customError?.status >= 500)
          return setErrors(prev => [
            ...prev.filter(() => !prev.includes('unknown-error')),
            'unknown-error',
          ]);

        if (customError?.data?.message?.includes('not enough'))
          return setErrors(prev => [
            ...prev.filter(() => !prev.includes('balance-error')),
            'balance-error',
          ]);
      }
    }
  };

  return isPendingTransaction ? (
    <CancelOfferOrListingLoading typeModal={typeModal} />
  ) : (
    <div className='cancel-offer-warning'>
      <div className='cancel-offer-warning__icon'>
        <WarningTriangleSVG width='42' height='38' type='alternative' />
      </div>
      <div className='cancel-offer-warning__text-block'>
        <div className='cancel-offer-warning__text-block__title'>
          {` Are you sure want to cancel ${
            typeModal === 'offer' ? 'your offer' : 'the listing'
          }?`}
        </div>
        <div className='cancel-offer-warning__text-block__ordinary'>
          {`This will cancel your ${
            typeModal === 'offer' ? 'offer' : 'listing'
          }. You will also be asked to confirm this
          cancelation from your wallet`}
        </div>
      </div>
      {!!errors.length && (
        <div className='cancel-offer-warning__error-block'>
          {errors.map((error: IErrorNames, index: number) => (
            <ErrorBlock key={`${index}-${error}`} errorName={error} />
          ))}
        </div>
      )}
      <div className='cancel-offer-warning__btns'>
        <Button
          typeButton='secondary'
          text='go back'
          style={{ width: '184px' }}
          onClick={handleModalClose}
        />
        <Button
          typeButton='white'
          text='continue'
          size='medium'
          style={{ width: '184px' }}
          onClick={cancelOfferFunction}
        />
      </div>
    </div>
  );
};

export default CancelOfferOrListingDefault;
