import { FC, memo } from 'react';
import { ISvgProps } from './types';

const NotePrimary: FC<ISvgProps> = ({ width = '48', height = '48' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M24 4.5C20.1433 4.5 16.3731 5.64366 13.1664 7.78634C9.95963 9.92903 7.46027 12.9745 5.98436 16.5377C4.50845 20.1008 4.12228 24.0216 4.8747 27.8043C5.62711 31.5869 7.4843 35.0615 10.2114 37.7886C12.9386 40.5157 16.4131 42.3729 20.1957 43.1253C23.9784 43.8777 27.8992 43.4916 31.4623 42.0156C35.0255 40.5397 38.071 38.0404 40.2137 34.8336C42.3564 31.6269 43.5 27.8567 43.5 24C43.4901 18.8313 41.4325 13.8772 37.7776 10.2224C34.1228 6.56755 29.1687 4.50991 24 4.5ZM23.625 13.5C24.07 13.5 24.505 13.632 24.875 13.8792C25.2451 14.1264 25.5334 14.4778 25.7037 14.889C25.874 15.3001 25.9186 15.7525 25.8318 16.189C25.745 16.6254 25.5307 17.0263 25.216 17.341C24.9013 17.6557 24.5004 17.87 24.064 17.9568C23.6275 18.0436 23.1751 17.999 22.764 17.8287C22.3528 17.6584 22.0014 17.37 21.7542 17C21.507 16.63 21.375 16.195 21.375 15.75C21.375 15.1533 21.6121 14.581 22.034 14.159C22.456 13.7371 23.0283 13.5 23.625 13.5ZM25.5 34.5H24C23.6022 34.5 23.2207 34.342 22.9393 34.0607C22.658 33.7794 22.5 33.3978 22.5 33V24C22.1022 24 21.7207 23.842 21.4393 23.5607C21.158 23.2794 21 22.8978 21 22.5C21 22.1022 21.158 21.7206 21.4393 21.4393C21.7207 21.158 22.1022 21 22.5 21H24C24.3978 21 24.7794 21.158 25.0607 21.4393C25.342 21.7206 25.5 22.1022 25.5 22.5V31.5C25.8978 31.5 26.2794 31.658 26.5607 31.9393C26.842 32.2206 27 32.6022 27 33C27 33.3978 26.842 33.7794 26.5607 34.0607C26.2794 34.342 25.8978 34.5 25.5 34.5Z'
      fill='#70B6F6'
    />
  </svg>
);
const NotePrimarySVG = memo(NotePrimary);
export default NotePrimarySVG;
