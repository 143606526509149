import { createAction } from '@reduxjs/toolkit';
import { ISelectFilters } from '@/entities/AllCards';

export interface ISelectFilterBurnedCardsAction {
  burnId: string;
  filters: ISelectFilters;
  search: string;
  page: string;
  step: string;
  clear?: 'clear';
}

export const getAllBurnedCardsAction =
  createAction<ISelectFilterBurnedCardsAction>(
    'BURNED_CARDS/GET_ALL_BURNED_CARDS',
  );

export const getBurnIdAction = createAction('BURNED_CARDS/GET_BURN_ID_CARDS');
