import { FC, memo } from 'react';
import { ISvgProps } from '../types';

const ArrowDownLeft: FC<ISvgProps> = ({ width = '16', height = '16' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='inherit'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3.55552 12.5C3.63486 12.684 3.78154 12.8307 3.96552 12.91V12.91C4.0533 12.9593 4.15033 12.99 4.25052 13H10.7505C10.9494 13 11.1402 12.921 11.2809 12.7803C11.4215 12.6397 11.5005 12.4489 11.5005 12.25C11.5005 12.0511 11.4215 11.8603 11.2809 11.7197C11.1402 11.579 10.9494 11.5 10.7505 11.5H6.06052L12.7805 4.78C12.8507 4.71071 12.9064 4.62818 12.9444 4.5372C12.9824 4.44622 13.002 4.3486 13.002 4.25C13.002 4.1514 12.9824 4.05378 12.9444 3.9628C12.9064 3.87182 12.8507 3.78929 12.7805 3.72C12.6399 3.57955 12.4493 3.50066 12.2505 3.50066C12.0518 3.50066 11.8611 3.57955 11.7205 3.72L5.00052 10.44V5.75C5.00052 5.55109 4.9215 5.36032 4.78085 5.21967C4.6402 5.07902 4.44943 5 4.25052 5C4.05161 5 3.86085 5.07902 3.72019 5.21967C3.57954 5.36032 3.50052 5.55109 3.50052 5.75V12.25C3.50399 12.3359 3.52261 12.4206 3.55552 12.5V12.5Z'
      fill='inherit'
    />
  </svg>
);
const ArrowDownLeftSVG = memo(ArrowDownLeft);
export default ArrowDownLeftSVG;
