import './buynowDefault.scss';
import { Buffer } from 'buffer';
import { CrossmintPayButton } from '@crossmint/client-sdk-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import { Transaction } from '@solana/web3.js';
import axios from 'axios';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IValidationState, PaymentMethodsTypes } from './types';
import { ErrorBlock } from '../../ErrorBlock/ErrorBlock';
import { IErrorNames } from '../../ErrorBlock/types';
import { SmallCardInfo } from '../../SmallCardInfo/SmallCardInfo';
import { IBuynowModalDefaultProps } from '../types';
import { getNFTCardRequest } from '@/shared/api/services/cards';
import {
  createQuickBuyTxRequest,
  sendQuickTxsRequest,
} from '@/shared/api/services/listings';
import {
  WalletSVG,
  SolanaSVG,
  Copy2SVG,
  DollarCircleSVG,
} from '@/shared/assets/svg';
import CreditCardSVG from '@/shared/assets/svg/CreditCardSVG';
import { USDCSVG } from '@/shared/assets/svg/OutboundShipment';
import { CloseWhiteSVG } from '@/shared/assets/svg/buttons';
import { AuthProviderContext } from '@/shared/lib/context/AuthProviderContext';
import { convertNumberInK } from '@/shared/lib/helpers/convertNumberInK';
import { limitWordCharacters } from '@/shared/lib/limitWordCharacters/limitWordCharacters';
import { signTransactionsMethod } from '@/shared/lib/signTransactions/signTransactionsMethod';
import { Alert } from '@/shared/ui/Alert/Alert';
import { RadioButton, IconButton, Button } from '@/shared/ui/Buttons';
import { Tooltip } from '@/shared/ui/Popovers';

const BuynowDefault: FC<IBuynowModalDefaultProps> = ({
  getDataCard,
  cardData,
  handleClose,
  changeTransactionStatus,
  setTransaction,
  transactionStatus,
  currentUserWallet,
  setError,
}) => {
  const { signTransaction } = useWallet();
  const { signTrans } = useContext(AuthProviderContext);
  const [copyText, setCopyText] = useState('Copy');
  const [paymentMethod, setPaymentMethod] =
    useState<PaymentMethodsTypes | null>(null);

  const [errors, setErrors] = useState<IErrorNames[]>([]);
  const isPendingTransaction = transactionStatus === 'pending';
  const isTransactionFailed = transactionStatus === 'axios-error';

  const {
    name,
    year,
    tokenId,
    autographed,
    ownerWallet,
    ownerName,
    frontImage,
    grade,
    gradingCompany,
    cardId,
    listing,
    status,
    category,
  } = cardData;

  const whCrossmintArgs = {
    buyerWallet: currentUserWallet,
    listingId: listing?.id,
  };

  const crossmintEnv =
    environment.APP_ENV === 'prod' ? 'production' : 'staging';

  const storagedKeyInfoCard = useMemo<IValidationState>(
    () => ({
      listing,
      status,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const closeOnCrossmintPay = () => {
    const timeId = setTimeout(() => {
      handleClose();
      clearTimeout(timeId);
    }, 30000);
  };

  const isListingError = errors.includes('listing-error');
  const isOwnerError = errors.includes('owner-error');
  const isPriceChangedError = errors.includes('price-error');

  const criticalErrors = errors.filter(
    (error: IErrorNames) => error !== 'price-error',
  );

  const groupedData = [
    { title: 'Year', value: year },
    { title: 'Autographed', value: autographed },
    { title: 'Collection', value: category },
    {
      title: 'Owner',
      value:
        ownerName ||
        (ownerWallet && limitWordCharacters(ownerWallet, 4, 'centerDots')),
    },
    { title: 'Grade', value: grade },
    { title: 'Grading company', value: gradingCompany },
  ];

  const toRightView = (
    title: string,
    value: string | boolean | number | null,
  ) => {
    if (title === 'Autographed') return value ? 'Yes' : 'No';
    return value;
  };

  const redirectToProfile = (wallet: string) => {
    window.open(`${window.location.origin}/account/${wallet}`, '_blank');
  };

  const handleCopyWalletAdress = (wallet: string) => {
    navigator.clipboard.writeText(wallet);
    setCopyText('Copied!');
  };

  const clickHandle = useCallback(
    (method: 'card' | 'crypto') => () => {
      setPaymentMethod(method);
    },
    [],
  );

  const checkCardInfo = (info: IValidationState) => {
    if (!info.listing) {
      setErrors(prev => [
        ...prev.filter(() => !prev.includes('listing-error')),
        'listing-error',
      ]);
      throw new Error('listing-error');
    }
    if (info?.listing?.price !== storagedKeyInfoCard?.listing?.price)
      setErrors(prev => [
        ...prev.filter(() => !prev.includes('price-error')),
        'price-error',
      ]);
  };

  useEffect(() => {
    setPaymentMethod('crypto');
  }, []);
  const handleQuickBuy = async () => {
    changeTransactionStatus('pending');
    setErrors([]);
    try {
      const updatedCard = (await getNFTCardRequest(cardData.tokenId)) as {
        data: ICard;
      };
      const { listing: listingUpdateCard, status: statusUpdateCard } =
        updatedCard.data;
      const responseToValidData = {
        listing: listingUpdateCard,
        status: statusUpdateCard,
      };
      if (responseToValidData) checkCardInfo(responseToValidData);

      const transactionRaw = (await createQuickBuyTxRequest({
        cardId,
      })) as {
        data: string;
      };

      const transactions = Transaction.from(
        Buffer.from(transactionRaw.data, 'base64'),
      );
      const transactionSigned = await signTransactionsMethod(
        transactions,
        signTransaction,
        signTrans,
      );
      if (
        transactionSigned instanceof Transaction &&
        transactionSigned.signatures.length > 0
      )
        try {
          const transactionSerialized = transactionSigned
            .serialize()
            .toString('base64');
          if (transactionSerialized) {
            const confirmedTransaction = (await sendQuickTxsRequest({
              tx: transactionSerialized,
            })) as {
              data: {
                txId: string;
                txUrl: string;
              };
            };
            setTransaction({
              transactionId: confirmedTransaction.data.txId,
              transactionUrl: confirmedTransaction.data.txUrl,
            });
          }

          getDataCard({ cardAddress: tokenId || '', withLoading: false });
          changeTransactionStatus('resolved');
        } catch (e) {
          console.log(e);
        }
    } catch (err) {
      changeTransactionStatus('adapter-error');
      setError?.('error');
      getDataCard({ cardAddress: cardId || '', withLoading: false });
      if (axios.isAxiosError(err)) {
        changeTransactionStatus('axios-error');
        const customError = err?.response as IResponse<IErrorMessage>;
        if (customError.status >= 500)
          return setErrors(prev => [
            ...prev.filter(() => !prev.includes('unknown-error')),
            'unknown-error',
          ]);

        if (customError?.data?.message.includes('not enough'))
          return setErrors(prev => [
            ...prev.filter(() => !prev.includes('balance-error')),
            'balance-error',
          ]);

        if (customError?.data?.message.includes('The owner has changed'))
          return setErrors(prev => [
            ...prev.filter(() => !prev.includes('owner-error')),
            'owner-error',
          ]);
      }
    }
  };

  return (
    <div className='buy-now-default'>
      <div className='buy-now-default__title'>
        <div className='buy-now-default__title__text'>Buy now</div>
        <div className='buy-now-default__title__close-btn'>
          <IconButton size='32' onClick={() => handleClose()}>
            <CloseWhiteSVG />
          </IconButton>
        </div>
      </div>
      <SmallCardInfo
        isTitleShort
        name={name}
        tokenId={tokenId}
        image={frontImage}
        handleModalClose={handleClose}
        isError={isListingError}
      />
      <div className='buy-now-default__content-wrapper'>
        <div className='buy-now-default__content-wrapper__details'>
          {groupedData.map(({ title, value }, index) => (
            <div
              key={`${name + index}-buy-now`}
              className='buy-now-default__content-wrapper__details__text-block'
            >
              <div className='buy-now-default__content-wrapper__details__text-block__name'>
                {title}
              </div>
              <div
                className={`buy-now-default__content-wrapper__details__text-block__value ${
                  title === 'Owner' ? ' link' : ''
                }`}
                {...(title === 'Owner'
                  ? { onClick: () => redirectToProfile(ownerWallet) }
                  : {})}
              >
                {toRightView(title, value)}
              </div>
            </div>
          ))}
        </div>
        <div className='buy-now-default__content-wrapper__amount'>
          <div className='buy-now-default__content-wrapper__amount__title'>
            <WalletSVG />
            Amount
          </div>
          <div className='buy-now-default__content-wrapper__amount__summary'>
            <div className='buy-now-default__content-wrapper__amount__summary__value'>
              {!!listing && convertNumberInK(listing.price, 'roundUp')}
            </div>
            <div className='buy-now-default__content-wrapper__amount__summary__currency'>
              <div className='buy-now-default__content-wrapper__amount__summary__currency__icon'>
                <USDCSVG fill='#3875C9' secondFill='#FFFFFF' />
              </div>
              <div className='buy-now-default__content-wrapper__amount__summary__currency__text'>
                USDC
              </div>
            </div>
          </div>
        </div>
        {!!isPriceChangedError && (
          <Alert
            size='small'
            text='This item has a recent price change'
            status='warning'
          />
        )}
        {/* here we are doing checking if card is listing and amount more than 1000 for crossmint */}
        <div className='buy-now-default__content-wrapper__pay-method'>
          <div className='buy-now-default__content-wrapper__pay-method__title'>
            Method
          </div>
          <div className='buy-now-default__content-wrapper__pay-method__buttons'>
            <div className='buy-now-default__content-wrapper__pay-method__buttons__item'>
              <RadioButton
                id='crypto'
                name='crypto'
                value='crypto'
                checked={paymentMethod === 'crypto'}
                onChange={clickHandle('crypto')}
              />
              <div className='buy-now-default__content-wrapper__pay-method__buttons__item__info'>
                <DollarCircleSVG />
                <span>Crypto</span>
              </div>
            </div>
            <div className='buy-now-default__content-wrapper__pay-method__buttons__item'>
              <RadioButton
                id='card'
                name='card'
                value='card'
                checked={paymentMethod === 'card'}
                onChange={clickHandle('card')}
              />
              <div className='buy-now-default__content-wrapper__pay-method__buttons__item__info'>
                <CreditCardSVG />
                <span>Credit Card</span>
              </div>
            </div>
          </div>
        </div>
        <div className='buy-now-default__content-wrapper__wallet'>
          <div className='buy-now-default__content-wrapper__wallet__left'>
            <SolanaSVG />
            <div className='buy-now-default__content-wrapper__wallet__left__content'>
              <p className='buy-now-default__content-wrapper__wallet__left__content__address'>
                {!!currentUserWallet &&
                  limitWordCharacters(currentUserWallet, 4, 'centerDots')}
              </p>
              <p className='buy-now-default__content-wrapper__wallet__left__content__solana'>
                Solana
              </p>
            </div>
          </div>
          <Tooltip placement='left' titleText={copyText}>
            <div
              className='buy-now-default__content-wrapper__wallet__right'
              onClick={() =>
                currentUserWallet && handleCopyWalletAdress(currentUserWallet)
              }
            >
              <Copy2SVG />
            </div>
          </Tooltip>
        </div>
        <div className='buy-now-default__content-wrapper__buy-btn'>
          {paymentMethod === 'crypto' ? (
            <Button
              fullWidth
              typeButton='white'
              text='Confirm and pay'
              imgLocation='right'
              isLoading={isPendingTransaction}
              disabled={isListingError || isOwnerError}
              onClick={handleQuickBuy}
            />
          ) : (
            <div>
              <CrossmintPayButton
                dismissOverlayOnClick
                clientId={environment.CROSSMINT_CLIENT_ID}
                mintTo={currentUserWallet}
                environment={crossmintEnv}
                paymentMethod='fiat'
                className='crossmint-btn'
                whPassThroughArgs={whCrossmintArgs}
                failureCallbackURL={`${environment.CLIENT_APP_URL}crossmint/failure/${tokenId}`}
                successCallbackURL={`${environment.CLIENT_APP_URL}crossmint/success/${tokenId}`}
                // eslint-disable-next-line react/jsx-sort-props
                onClick={closeOnCrossmintPay}
                mintConfig={{
                  buyPrice: listing?.price,
                  mintHash: tokenId,
                  quantity: '1',
                  sellerWallet: ownerWallet,
                  totalPrice: listing?.price,
                  type: 'solana-auction-house',
                }}
              />
            </div>
          )}
        </div>
      </div>
      {!!(isTransactionFailed || !!criticalErrors.length) && (
        <div className='buy-now-default__error-block'>
          {criticalErrors.map((error: IErrorNames, index: number) => (
            <ErrorBlock key={`${index}-${error}`} errorName={error} />
          ))}
        </div>
      )}
    </div>
  );
};

export default BuynowDefault;
