import {
  Dispatch,
  FC,
  SetStateAction,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cls from './headerModal.module.scss';
import { SentMoney } from '../SentMoney/SentMoney';
import { getAuthData, logOutReq } from '@/entities/Auth';
import {
  fetchUserInfo,
  getBalanceSOL,
  getBalanceUSDC,
} from '@/entities/Headers';
import { fetchPublicProfileInfo } from '@/entities/PublicProfile';
import { getTypeAuthMagicLink } from '@/features/MagicLinkModal';
import { sendFormActions } from '@/features/SendSolanaForm';
import { PinionSVG, SolanaSVG, Copy2SVG, UsdcSVG } from '@/shared/assets/svg';
import {
  ArrowUpRightSVG,
  ArrowDownLeftSVG,
  ProfileSVG,
  LogoutSVG,
} from '@/shared/assets/svg/HeaderPopover';
import SolanaIcon from '@/shared/assets/svg/HeaderPopover/SolonsLogo.svg';
import {
  getRoutePublicAccount,
  getRouteSettings,
  getRouteShipmentInbound,
  getRouteShipmentOutbound,
} from '@/shared/const/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import { AuthProviderContext } from '@/shared/lib/context/AuthProviderContext';
import { cId } from '@/shared/lib/getIdFromPathName/getIdFromPathName';
import { sliceString } from '@/shared/lib/helpers/scliceStringFunction';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useLogOut } from '@/shared/lib/hooks/useLogOut/useLogOut';
import { Button } from '@/shared/ui/Buttons';
import { Spinner } from '@/shared/ui/Loaders';
import { Modal } from '@/shared/ui/Modal';
import { Tooltip } from '@/shared/ui/Popovers';
import { HStack, VStack } from '@/shared/ui/Stack';

interface IHeaderPopoverProps {
  anchorEl: HTMLDivElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLDivElement | null>>;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}

export const HeaderModal: FC<IHeaderPopoverProps> = memo(
  ({ anchorEl, setAnchorEl, setOpenModal, open }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const { id, pathName } = cId(pathname);
    const balanceSOL = useAppSelector(getBalanceSOL);
    const balanceUSDC = useAppSelector(getBalanceUSDC);
    const { wallet } = useAppSelector(getAuthData);
    const typeConnect = useAppSelector(getTypeAuthMagicLink);
    const [isLoadingLogout, setIsLoadingLogout] = useState(false);
    const { logOutRedux } = useLogOut();
    const { logoutMagicLink } = useContext(AuthProviderContext);
    const [copyText, setCopyText] = useState('Copy');
    const [openSent, setOpenSent] = useState(false);

    const handleClose = useCallback(() => {
      setAnchorEl(null);
    }, [setAnchorEl]);

    const redirectInProfile = useCallback(() => {
      handleClose();
      setOpenModal(false);
    }, [handleClose, setOpenModal]);

    const redirectInSettings = useCallback(() => {
      navigate(getRouteSettings());
      handleClose();
      setOpenModal(false);
    }, [handleClose, navigate, setOpenModal]);

    const redirectToShipment = useCallback(
      (typeShipment: ITypeShipments) => {
        navigate(
          typeShipment === 'inbound'
            ? getRouteShipmentInbound()
            : getRouteShipmentOutbound(),
          { state: { typeShipment } },
        );
        handleClose();
        setOpenModal(false);
      },
      [handleClose, navigate, setOpenModal],
    );
    const logOut = useCallback(async () => {
      setIsLoadingLogout(true);
      const res = await dispatch(logOutReq());
      if (res.meta.requestStatus === 'fulfilled') {
        setIsLoadingLogout(false);
        logOutRedux(undefined, typeConnect !== '', true, logoutMagicLink);
        handleClose();
        setOpenModal(false);
        if (id && pathName === 'account')
          dispatch(fetchPublicProfileInfo({ user: id }));
      }
      if (res.meta.requestStatus === 'rejected') setIsLoadingLogout(false);
    }, [
      dispatch,
      typeConnect,
      logoutMagicLink,
      logOutRedux,
      handleClose,
      setOpenModal,
      id,
      pathName,
    ]);

    const handleCopyWalletAddress = useCallback((localWallet: string) => {
      navigator.clipboard.writeText(localWallet);
      setCopyText('Copied!');
    }, []);

    const onOpenSendMoney = (type: 'receive' | 'send') => () => {
      localStorage.setItem('typeTranslate', type);
      setOpenSent(true);
    };

    const onClosePopover = useCallback(() => {
      setAnchorEl(null);
      dispatch(sendFormActions.setInitState());
    }, [dispatch, setAnchorEl]);

    useEffect(() => {
      if (wallet && open) dispatch(fetchUserInfo({ user: wallet }));
    }, [dispatch, open, wallet]);

    return (
      <Modal isOpen={open} isOverlay={false} blur={false} onClose={handleClose}>
        <div className={cls.headerModal}>
          {openSent ? (
            <VStack gap={0.5} className={cls.popover}>
              <SentMoney
                setOpenSent={setOpenSent}
                setOpenModal={onClosePopover}
              />
            </VStack>
          ) : (
            <VStack
              gap={0.5}
              className={classNames(cls.popover, {
                [cls.isNotMagic]: typeConnect === '',
              })}
            >
              <VStack max gap={0.5} className={cls.wallet}>
                <HStack max justify='between' align='center'>
                  <HStack gap={0.625} justify='between' className={cls.icon}>
                    <SolanaSVG />
                    <div>
                      <p>{sliceString(wallet || '')}</p>
                      <p>Solana</p>
                    </div>
                  </HStack>
                  <Tooltip placement='bottom' titleText={copyText}>
                    <div
                      className={cls.right}
                      onClick={() => handleCopyWalletAddress(wallet || '')}
                    >
                      <Copy2SVG />
                    </div>
                  </Tooltip>
                </HStack>
                <VStack className={cls.info} gap={0.75}>
                  <HStack gap={0.5}>
                    <UsdcSVG fill='#3875C9' secondFill='#FFFFFF' />
                    <span>{balanceUSDC} USDC</span>
                  </HStack>
                  <HStack gap={0.5}>
                    <SolanaIcon
                      style={{ background: '#000000', borderRadius: '50%' }}
                    />
                    <span>{balanceSOL} SOL</span>
                  </HStack>
                </VStack>
                {typeConnect !== '' && (
                  <HStack max justify='between' align='center'>
                    <Button
                      typeButton='primary'
                      text='receive'
                      size='small'
                      className={cls.btn}
                      onClick={onOpenSendMoney('receive')}
                    />
                    <Button
                      typeButton='secondary'
                      text='send '
                      size='small'
                      className={cls.btn}
                      onClick={onOpenSendMoney('send')}
                    />
                  </HStack>
                )}
              </VStack>
              {wallet ? (
                <a
                  href={getRoutePublicAccount(wallet)}
                  className={cls['menu-el']}
                  onClick={redirectInProfile}
                >
                  <ProfileSVG width='16' height='16' />
                  <p>Profile</p>
                </a>
              ) : (
                <div className={cls['menu-el']} onClick={redirectInProfile}>
                  <ProfileSVG width='16' height='16' />
                  <p>Profile</p>
                </div>
              )}
              <div className={cls['menu-el']} onClick={redirectInSettings}>
                <PinionSVG />
                <p>Setting</p>
              </div>
              <div className={cls.separator} />
              <VStack max gap={0.25} className={cls.shipment}>
                <h3>My Shipments</h3>
                <VStack max gap={0.25}>
                  <HStack
                    max
                    gap={0.5}
                    onClick={() => redirectToShipment('inbound')}
                  >
                    <ArrowUpRightSVG />
                    <p>Deposit</p>
                  </HStack>
                  <HStack
                    max
                    gap={0.5}
                    onClick={() => redirectToShipment('outbound')}
                  >
                    <ArrowDownLeftSVG />
                    <p>Withdraw</p>
                  </HStack>
                </VStack>
              </VStack>
              <div className={cls.separator} />
              <div className={cls['menu-el']} onClick={logOut}>
                {isLoadingLogout ? (
                  <Spinner size={16} />
                ) : (
                  <LogoutSVG width='16' height='16' />
                )}
                <p>Log out</p>
              </div>
            </VStack>
          )}
        </div>
      </Modal>
    );
  },
);
