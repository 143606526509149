import { FC } from 'react';
import './listCardSuccess.scss';
import { useLocation } from 'react-router-dom';
import { SmallCardInfo } from '../../SmallCardInfo/SmallCardInfo';
import { SuccessTitle } from '../../SuccessTitle/SuccessTitle';
import { IListCardSuccessModalProps } from '../types';
import { OutsideLinkSVG, UsdcSVG } from '@/shared/assets/svg';
import { CloseWhiteSVG } from '@/shared/assets/svg/buttons';
import { getRouteNFTCard } from '@/shared/const/router';
import { convertNumberInK } from '@/shared/lib/helpers/convertNumberInK';
import { limitWordCharacters } from '@/shared/lib/limitWordCharacters/limitWordCharacters';
import { IconButton, Button } from '@/shared/ui/Buttons';
import { HStack } from '@/shared/ui/Stack';

const ListCardSuccess: FC<IListCardSuccessModalProps> = ({
  cardData,
  handleModalClose,
  typeModal,
  transaction,
}) => {
  const { pathname } = useLocation();

  const { name, tokenId, frontImage } = cardData;
  const { transactionId, transactionUrl, price } = transaction;

  const titleText =
    typeModal === 'update-listing'
      ? 'Listing price updated successfully!'
      : 'Your card has been listed for sale!';

  const openCard = () => {
    if (pathname.includes('/assets/solana/')) handleModalClose();
    else window.location.href = getRouteNFTCard(tokenId);
  };

  return (
    <div className='list-card-modal-success'>
      <div className='list-card-modal-success__close-btn'>
        <IconButton size='32' onClick={handleModalClose}>
          <CloseWhiteSVG />
        </IconButton>
      </div>
      <SuccessTitle text={titleText} font='H4-CL' />
      <SmallCardInfo
        name={name}
        tokenId={tokenId}
        image={frontImage}
        isTitleShort={false}
        handleModalClose={handleModalClose}
      />
      <div className='list-card-modal-success__wrapper'>
        <div className='list-card-modal-success__wrapper__asking-price'>
          <div className='list-card-modal-success__wrapper__asking-price__text'>
            Asking price
          </div>
          <div className='list-card-modal-success__wrapper__asking-price__currency'>
            <UsdcSVG fill='#3875C9' secondFill='#FFFFFF' />
            <span className='list-card-modal-success__wrapper__asking-price__currency__value'>
              ${convertNumberInK(price)}
            </span>
          </div>
        </div>
        <div className='list-card-modal-success__wrapper__transaction'>
          <div className='list-card-modal-success__wrapper__transaction__title'>
            Transaction
          </div>
          <div className='list-card-modal-success__wrapper__transaction__description'>
            <a
              href={transactionUrl}
              target='_blank'
              rel='noreferrer'
              className='list-card-modal-success__wrapper__transaction__description__link'
            >
              <HStack gap={0.25}>
                {limitWordCharacters(transactionId, 4, 'centerDots')}
                <OutsideLinkSVG />
              </HStack>
            </a>
          </div>
        </div>
      </div>
      <div className='list-card-modal-success__button'>
        <Button
          fullWidth
          typeButton='secondary'
          text='go to card'
          onClick={openCard}
        />
      </div>
    </div>
  );
};

export default ListCardSuccess;
