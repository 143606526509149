import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
import {
  setBuyerOffers,
  setListings,
  setLoadingListings,
  setLoadingMarketPlaceCardsSlice,
  setLoadingOffers,
  setMarketPlaceCardsSlice,
  setNFTOffers,
  setSellerOffers,
} from '../../model/slice/marketplace';
import {
  getAllMarketPlaceCards,
  getNFTCardOffers,
  IGetOffersBySideProps,
  reloadAllMarketPlaceCardsAction,
  getListings,
  getBuyerOffersBySide,
  getSellerOffersBySide,
} from '../actions/marketplacePageActions';
import { ISelectFiltersAction } from '@/entities/AllCards';
import {
  GetCardOffersRequest,
  getBuyerOffersRequest,
  getSellerListingRequest,
  getSellerOffersRequest,
} from '@/shared/api/services/listings';
import {
  getAllMarketplaceCardsRequest,
  reloadAllMarketplaceCardsRequest,
} from '@/shared/api/services/marketplace';
import { errorHandlerForSaga } from '@/shared/lib/ErrorHandlerForSaga/ErrorHandlerForSaga';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

function* workerGetAllMarketPlaceCards({
  payload,
}: PayloadAction<ISelectFiltersAction>) {
  try {
    yield put(setLoadingMarketPlaceCardsSlice(true));
    const response: IResponse<IAllUserCards> =
      yield getAllMarketplaceCardsRequest(payload);
    yield put(
      setMarketPlaceCardsSlice({
        ...response.data,
        clear: payload.clear ? payload.clear : undefined,
      }),
    );
    yield put(setLoadingMarketPlaceCardsSlice(false));
  } catch (error) {
    errorHandlerForSaga(error);
    yield put(setLoadingMarketPlaceCardsSlice(false));
  }
}

export function* watcherGetAllMarketPlaceCards() {
  yield takeEvery(
    getAllMarketPlaceCards.toString(),
    workerGetAllMarketPlaceCards,
  );
}

function* workerReloadAllMarketPlaceCards({
  payload,
}: PayloadAction<ISelectFiltersAction>) {
  try {
    yield put(setLoadingMarketPlaceCardsSlice(true));
    yield reloadAllMarketplaceCardsRequest(payload.wallet);
    showSnackbar(
      'We’ve queued this item for an update! Check back in a minute...',
      'success',
    );
    yield put(getAllMarketPlaceCards({ ...payload, clear: 'clear' }));
    yield put(setLoadingMarketPlaceCardsSlice(false));
  } catch (error) {
    errorHandlerForSaga(error);
    yield put(setLoadingMarketPlaceCardsSlice(false));
  }
}

export function* watcherReloadAllMarketPlaceCards() {
  yield takeEvery(
    reloadAllMarketPlaceCardsAction.toString(),
    workerReloadAllMarketPlaceCards,
  );
}

function* workerGetNFTOffers({ payload }: PayloadAction<string>) {
  try {
    yield put(setLoadingOffers(true));
    const response: IResponse<IOffer[]> = yield GetCardOffersRequest({
      cardId: payload,
    });
    yield put(setNFTOffers(response.data));
    yield put(setLoadingOffers(false));
  } catch (error) {
    errorHandlerForSaga(error);
    yield put(setLoadingOffers(false));
  }
}

export function* watcherGetNFTOffers() {
  yield takeEvery(getNFTCardOffers.toString(), workerGetNFTOffers);
}

function* workerGetSellerOffersBySide({
  payload,
}: PayloadAction<IGetOffersBySideProps>) {
  try {
    yield put(setLoadingOffers(true));
    const response: IResponse<IBuyerOffer[]> = yield getSellerOffersRequest(
      payload,
    );
    yield put(setSellerOffers(response.data));

    yield put(setLoadingOffers(false));
  } catch (error) {
    errorHandlerForSaga(error);
    yield put(setLoadingOffers(false));
  }
}

export function* watcherGetSellerOffersBySide() {
  yield takeEvery(
    getSellerOffersBySide.toString(),
    workerGetSellerOffersBySide,
  );
}

function* workerGetBuyerOffersBySide({
  payload,
}: PayloadAction<IGetOffersBySideProps>) {
  try {
    yield put(setLoadingOffers(true));
    const response: IResponse<IBuyerOffer[]> = yield getBuyerOffersRequest(
      payload,
    );
    yield put(setBuyerOffers(response.data));

    yield put(setLoadingOffers(false));
  } catch (error) {
    errorHandlerForSaga(error);
    yield put(setLoadingOffers(false));
  }
}

export function* watcherGetBuyerOffersBySide() {
  yield takeEvery(getBuyerOffersBySide.toString(), workerGetBuyerOffersBySide);
}

function* workerGetListings({ payload }: PayloadAction<IGetOffersBySideProps>) {
  try {
    yield put(setLoadingListings(true));
    const response: IResponse<IListing[]> = yield getSellerListingRequest(
      payload,
    );
    yield put(setListings(response.data));
    yield put(setLoadingListings(false));
  } catch (error) {
    errorHandlerForSaga(error);
    yield put(setLoadingListings(false));
  }
}

export function* watcherGetListings() {
  yield takeEvery(getListings.toString(), workerGetListings);
}
