import { FC, memo, useCallback, useEffect, useState } from 'react';
import './listCard.scss';
import ListCardDefault from './ListCardDefault/ListCardDefault';
import ListCardSuccess from './ListCardSuccess/ListCardSuccess';
import { IListCardModalProps } from './types';
import { Modal } from '@/shared/ui/Modal';

export const ListCardModal: FC<IListCardModalProps> = memo(
  ({
    getDataCard,
    open,
    setOpen,
    cardData,
    subjectId,
    typeModal,
    currentUserWallet,
    modalFromPage,
  }) => {
    const [listingPrice, setListingPrice] = useState<string>('');
    const [transactionStatus, setTransactionStatus] = useState('none');
    const [error, setError] = useState<undefined | string>(undefined);
    const [transaction, setTransaction] = useState({
      price: '',
      transactionId: '',
      transactionUrl: '',
    });

    const handleModalClose = useCallback(() => {
      setOpen('listCard', { isOpen: false, typeModal: '' });
      setTransactionStatus('none');
      setListingPrice('');
    }, [setOpen]);

    const changeTransactionStatus = (status: string) => {
      setTransactionStatus(status);
    };

    useEffect(() => {
      if (error) handleModalClose();
    }, [error, handleModalClose]);
    return (
      <Modal
        isOpen={open}
        className='list-card-modal'
        onClose={handleModalClose}
      >
        {transactionStatus === 'resolved' ? (
          <ListCardSuccess
            getDataCard={getDataCard}
            cardData={cardData}
            handleModalClose={handleModalClose}
            typeModal={typeModal}
            transaction={transaction}
          />
        ) : (
          <ListCardDefault
            getDataCard={getDataCard}
            cardData={cardData}
            handleModalClose={handleModalClose}
            transactionStatus={transactionStatus}
            changeTransactionStatus={changeTransactionStatus}
            subjectId={subjectId}
            typeModal={typeModal}
            setError={setError}
            setTransaction={setTransaction}
            currentUserWallet={currentUserWallet}
            modalFromPage={modalFromPage}
            listingPrice={listingPrice}
            setListingPrice={setListingPrice}
          />
        )}
      </Modal>
    );
  },
);
