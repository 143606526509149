import './makeOfferDefault.scss';
import { Buffer } from 'buffer';
import { useWallet } from '@solana/wallet-adapter-react';
import { Transaction } from '@solana/web3.js';
import axios from 'axios';
import { CSSProperties, FC, useContext, useState } from 'react';
import { ErrorBlock } from '../../ErrorBlock/ErrorBlock';
import { IErrorNames } from '../../ErrorBlock/types';
import { SmallCardInfo } from '../../SmallCardInfo/SmallCardInfo';
import { IMakeOfferDefaultModalProps } from '../types';
import { CurrencyDropdown } from '@/features/CurrencyDropdown';
import { getNFTCardOffers } from '@/pages/Marketplace';
import {
  createOfferTxRequest,
  createUpdateOfferTxRequest,
  sendMakeOfferTxsRequest,
  sendUpdateOfferTxsRequest,
} from '@/shared/api/services/listings';
import { CloseWhiteSVG } from '@/shared/assets/svg/buttons';
import { AuthProviderContext } from '@/shared/lib/context/AuthProviderContext';
import { convertNumberInK } from '@/shared/lib/helpers/convertNumberInK';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { limitWordCharacters } from '@/shared/lib/limitWordCharacters/limitWordCharacters';
import { signTransactionsMethod } from '@/shared/lib/signTransactions/signTransactionsMethod';
import { IconButton, Button } from '@/shared/ui/Buttons';
import { Input } from '@/shared/ui/Inputs';
import { Spinner } from '@/shared/ui/Loaders';

const MakeOfferDefault: FC<IMakeOfferDefaultModalProps> = ({
  getDataCard,
  cardData,
  handleModalClose,
  transactionStatus,
  changeTransactionStatus,
  offerPrice,
  initialPrice,
  setOfferPrice,
  setTransaction,
  typeModal,
  offerId,
}) => {
  const { signTransaction } = useWallet();
  const dispatch = useAppDispatch();
  const { signTrans: signTransactionWeb3 } = useContext(AuthProviderContext);
  const {
    name,
    tokenId,
    frontImage,
    autographed,
    year,
    ownerName,
    ownerWallet,
    insuredValue,
    cardId,
    cardAddress,
    category,
  } = cardData;
  const [currency, setCurrency] = useState<ICurrency>('USDC');
  const [errors, setErrors] = useState<IErrorNames[]>([]);

  const isPendingTransaction = transactionStatus === 'pending';
  const isTransactionFailed = transactionStatus === 'axios-error';
  const isPriceWrong =
    (typeModal === 'update-offer' &&
      Number(offerPrice) === Number(initialPrice)) ||
    !offerPrice ||
    Number(offerPrice) === 0;

  const handleCurrencyChange = (value: ICurrency) => {
    setCurrency(value);
  };

  const makeOfferStyles: CSSProperties = {
    alignItems: 'center',
    background: '#121212',
    borderRadius: '0.5rem',
    color: '#E8EAED',
    display: 'flex',
    gap: '0.25rem',
    padding: '0.5rem',
    position: 'absolute',
    right: ' 0.25rem',
    top: '0.7rem',
  };

  const makeOfferFunction = async () => {
    changeTransactionStatus('pending');
    setErrors([]);
    try {
      const createTx = async () => {
        if (typeModal === 'make-offer') {
          const createdTx = (await createOfferTxRequest({
            cardId,
            currency,
            price: Number(offerPrice),
          })) as { data: string };

          return createdTx.data;
        }

        if (typeModal === 'update-offer' && !!offerId) {
          const createdTx = (await createUpdateOfferTxRequest({
            currency,
            offerId,
            price: Number(offerPrice),
          })) as { data: string };

          return createdTx.data;
        }
      };

      const ut = createTx() as Promise<string>;

      const transaction = Transaction.from(
        Buffer.from(await ut, 'base64'),
      ) as Transaction;
      const transactionSigned = await signTransactionsMethod(
        transaction,
        signTransaction,
        signTransactionWeb3,
      );

      if (
        transactionSigned instanceof Transaction &&
        transactionSigned.signatures.length > 0
      ) {
        const serializedTransactions = transactionSigned
          .serialize()
          .toString('base64');

        if (typeModal === 'make-offer') {
          const TXSended = (await sendMakeOfferTxsRequest({
            tx: serializedTransactions,
          })) as {
            data: {
              txId: string;
              txUrl: string;
            };
          };
          setTransaction({
            transactionId: TXSended.data.txId,
            transactionUrl: TXSended.data.txUrl,
          });
        }

        if (typeModal === 'update-offer') {
          const TXSended = (await sendUpdateOfferTxsRequest({
            tx: serializedTransactions,
          })) as {
            data: {
              txId: string;
              txUrl: string;
            };
          };
          setTransaction({
            transactionId: TXSended.data.txId,
            transactionUrl: TXSended.data.txUrl,
          });
        }

        getDataCard({ cardAddress: cardAddress || '', withLoading: false });
        dispatch(getNFTCardOffers(cardId));
        changeTransactionStatus('resolved');
      }
    } catch (err) {
      changeTransactionStatus('adapter-error');
      if (axios.isAxiosError(err)) {
        changeTransactionStatus('axios-error');
        const customError = err.response as IResponse<IErrorMessage>;
        if (customError.status >= 500)
          return setErrors(prev => [
            ...prev.filter(() => !prev.includes('unknown-error')),
            'unknown-error',
          ]);

        if (customError?.data?.message.includes('not enough'))
          return setErrors(prev => [
            ...prev.filter(() => !prev.includes('balance-error')),
            'balance-error',
          ]);

        if (customError?.data?.message.includes('Invalid price'))
          return setErrors(prev => [
            ...prev.filter(() => !prev.includes('same-offer-price-error')),
            'same-offer-price-error',
          ]);
      }
    }
  };

  const redirectToProfile = (wallet: string) => {
    window.open(`${window.location.origin}/account/${wallet}`, '_blank');
  };

  return (
    <div
      className='make-offer-modal-default'
      style={{ paddingTop: isPendingTransaction ? '2rem' : '' }}
    >
      <div className='make-offer-modal-default__close-btn'>
        {!isPendingTransaction && (
          <IconButton size='32' onClick={handleModalClose}>
            <CloseWhiteSVG />
          </IconButton>
        )}
      </div>
      <div className='make-offer-modal-default__title'>
        {typeModal === 'make-offer' ? 'Make offer' : 'Update offer'}
      </div>
      <div className='make-offer-modal-default__content'>
        <div className='make-offer-modal-default__content__wrapper'>
          <SmallCardInfo
            name={name}
            tokenId={tokenId}
            image={frontImage}
            isTitleShort={false}
            handleModalClose={handleModalClose}
          />
          <div className='make-offer-modal-default__content__wrapper__details'>
            <div className='make-offer-modal-default__content__wrapper__details__item'>
              <span className='make-offer-modal-default__content__wrapper__details__item__name'>
                Collection
              </span>
              <span className='make-offer-modal-default__content__wrapper__details__item__value'>
                {category}
              </span>
            </div>
            <div className='make-offer-modal-default__content__wrapper__details__item'>
              <span className='make-offer-modal-default__content__wrapper__details__item__name'>
                Insured value
              </span>
              <span className='make-offer-modal-default__content__wrapper__details__item__value'>
                {`${convertNumberInK(insuredValue)} $`}
              </span>
            </div>
            <div className='make-offer-modal-default__content__wrapper__details__item'>
              <span className='make-offer-modal-default__content__wrapper__details__item__name'>
                Autographed
              </span>
              <span className='make-offer-modal-default__content__wrapper__details__item__value'>
                {autographed ? 'Yes' : 'No'}
              </span>
            </div>
            <div className='make-offer-modal-default__content__wrapper__details__item'>
              <span className='make-offer-modal-default__content__wrapper__details__item__name'>
                Owner
              </span>
              <span
                className='make-offer-modal-default__content__wrapper__details__item__value blue-text'
                onClick={() => redirectToProfile(ownerWallet)}
              >
                {ownerName ||
                  (ownerWallet &&
                    limitWordCharacters(ownerWallet, 4, 'centerDots'))}
              </span>
            </div>
            <div className='make-offer-modal-default__content__wrapper__details__item'>
              <span className='make-offer-modal-default__content__wrapper__details__item__name'>
                Year
              </span>
              <span className='make-offer-modal-default__content__wrapper__details__item__value'>
                {year}
              </span>
            </div>
          </div>
          {isPendingTransaction ? (
            <div className='list-card-modal-default__info__confirming'>
              <Spinner size={40} />
              <p className='list-card-modal-default__info__confirming__description'>
                We are processing your request...
              </p>
            </div>
          ) : (
            <div className='make-offer-modal-default__content__wrapper__input-block'>
              <Input
                isValidate
                type='number'
                value={offerPrice}
                max={999999999}
                label='Price'
                placeholder='Enter price'
                isError={!!errors.length}
                onChange={setOfferPrice}
              >
                <div>
                  <CurrencyDropdown
                    currency={currency}
                    handleCurrencyChange={handleCurrencyChange}
                    styles={makeOfferStyles}
                  />
                </div>
              </Input>
            </div>
          )}
          {!!(isTransactionFailed || !!errors.length) && (
            <div className='make-offer-modal-default__content__error-block'>
              {errors.map((error: IErrorNames, index: number) => (
                <ErrorBlock key={`${index}-${error}`} errorName={error} />
              ))}
            </div>
          )}
        </div>
        <div className='make-offer-modal-default__content__btn'>
          <Button
            fullWidth
            typeButton='white'
            text={typeModal === 'make-offer' ? 'Make offer' : 'Update offer'}
            size='medium'
            disabled={isPendingTransaction || isPriceWrong}
            onClick={makeOfferFunction}
          />
        </div>
      </div>
    </div>
  );
};

export default MakeOfferDefault;
