import { createAction } from '@reduxjs/toolkit';
import { ISelectFiltersAction } from '@/entities/AllCards';

export type IGetOffersBySideProps = {
  params: { wallet: string; days?: number };
};

export type IGetListingsProps = {
  params: { wallet: string; days?: number };
};

export const getAllMarketPlaceCards = createAction<ISelectFiltersAction>(
  'CARDS/GET_ALL_MARKETPLACE_CARDS',
);

export const reloadAllMarketPlaceCardsAction =
  createAction<ISelectFiltersAction>('CARDS/RELOAD_ALL_MARKETPLACE_CARDS');

export const getNFTCardOffers = createAction<string>('NFT/GET_OFFERS');

export const getSellerOffersBySide = createAction<IGetOffersBySideProps>(
  'NFT/GET_SELLER_OFFERS__SIDE',
);
export const getBuyerOffersBySide = createAction<IGetOffersBySideProps>(
  'NFT/GET_BUYER_OFFERS__SIDE',
);

export const getListings = createAction<IGetListingsProps>('NFT/GET_LISTINGS');
