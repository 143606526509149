import { FC, memo } from 'react';
import { ISvgProps } from './types';

const Solana: FC<ISvgProps> = ({ width = '32', height = '32' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.2221 19.5117C10.3309 19.4077 10.4784 19.3493 10.6322 19.3493H24.8202C25.0786 19.3493 25.208 19.6484 25.0252 19.8232L22.2217 22.5042C22.1129 22.6082 21.9654 22.6667 21.8116 22.6667H7.62363C7.36519 22.6667 7.2358 22.3676 7.41861 22.1927L10.2221 19.5117Z'
      fill='white'
    />
    <path
      d='M10.2221 9.49581C10.3309 9.39177 10.4784 9.33333 10.6322 9.33334H24.8202C25.0786 9.33334 25.208 9.63245 25.0252 9.80731L22.2217 12.4883C22.1129 12.5923 21.9654 12.6507 21.8116 12.6507H7.62363C7.36519 12.6507 7.2358 12.3516 7.41861 12.1768L10.2221 9.49581Z'
      fill='white'
    />
    <path
      d='M22.2217 14.4719C22.1129 14.3678 21.9654 14.3094 21.8116 14.3094H7.62363C7.36519 14.3094 7.2358 14.6085 7.41861 14.7834L10.2221 17.4643C10.3309 17.5684 10.4784 17.6268 10.6322 17.6268H24.8202C25.0786 17.6268 25.208 17.3277 25.0252 17.1528L22.2217 14.4719Z'
      fill='white'
    />
  </svg>
);
const SolanaSVG = memo(Solana);
export default SolanaSVG;
