import { FC } from 'react';
import './makeOfferSuccess.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { SmallCardInfo } from '../../SmallCardInfo/SmallCardInfo';
import { SuccessTitle } from '../../SuccessTitle/SuccessTitle';
import { IMakeOfferSuccessModalProps } from '../types';
import { OutsideLinkSVG, UsdcSVG } from '@/shared/assets/svg';
import { CloseWhiteSVG } from '@/shared/assets/svg/buttons';
import {
  getRouteMarketplaceCards,
  getRouteNFTCard,
} from '@/shared/const/router';
import { convertNumberInK } from '@/shared/lib/helpers/convertNumberInK';
import { limitWordCharacters } from '@/shared/lib/limitWordCharacters/limitWordCharacters';
import { IconButton, Button } from '@/shared/ui/Buttons';
import { HStack } from '@/shared/ui/Stack';

const MakeOfferSuccess: FC<IMakeOfferSuccessModalProps> = ({
  cardData,
  handleModalClose,
  offerPrice,
  transaction,
  typeModal,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { name, tokenId, frontImage } = cardData;
  const { transactionId, transactionUrl } = transaction;
  const titleText =
    typeModal === 'make-offer' ? 'Offer submitted!' : 'Offer updated!';

  const redirectMarketplace = () => navigate(getRouteMarketplaceCards());
  const openCard = () => {
    if (pathname.includes('/assets/solana/')) handleModalClose();
    else window.location.href = getRouteNFTCard(tokenId);
  };

  return (
    <div className='make-offer-modal-success'>
      <div className='make-offer-modal-success__close-btn'>
        <IconButton size='32' onClick={handleModalClose}>
          <CloseWhiteSVG />
        </IconButton>
      </div>
      <SuccessTitle text={titleText} font='H4-CL' />
      <div className='make-offer-modal-success__ordinary-text'>
        We will notify you if the owner accepts your offer
      </div>
      <SmallCardInfo
        name={name}
        tokenId={tokenId}
        image={frontImage}
        isTitleShort={false}
        handleModalClose={handleModalClose}
      />
      <div className='make-offer-modal-success__wrapper'>
        <div className='make-offer-modal-success__wrapper__asking-price'>
          <div className='make-offer-modal-success__wrapper__asking-price__text'>
            Your offer price
          </div>
          <div className='make-offer-modal-success__wrapper__asking-price__currency'>
            <UsdcSVG fill='#3875C9' secondFill='#FFFFFF' />
            <span className='make-offer-modal-success__wrapper__asking-price__currency__value'>
              ${convertNumberInK(offerPrice)}
            </span>
          </div>
        </div>
        <div className='make-offer-modal-success__wrapper__transaction'>
          <div className='make-offer-modal-success__wrapper__transaction__title'>
            Transaction
          </div>
          <div className='make-offer-modal-success__wrapper__transaction__description'>
            <a
              href={transactionUrl}
              target='_blank'
              rel='noreferrer'
              className='make-offer-modal-success__wrapper__transaction__description'
            >
              <HStack gap={0.25}>
                {limitWordCharacters(transactionId, 4, 'centerDots')}
                <OutsideLinkSVG />
              </HStack>
            </a>
          </div>
        </div>
      </div>
      <div className='make-offer-modal-success__button'>
        <Button
          fullWidth
          typeButton='secondary'
          text='go to card'
          onClick={openCard}
        />
        <Button
          fullWidth
          typeButton='primary-alternative'
          text='View Marketplace'
          style={{ minWidth: '14.81rem' }}
          size='large-alternative'
          onClick={redirectMarketplace}
        />
      </div>
    </div>
  );
};

export default MakeOfferSuccess;
